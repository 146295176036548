
* {
   -webkit-user-select: none;
}

$unit:vmin;
$unitMult:1;

.splash {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  width: 100%;
  height: 100%;
  background-color: #f3f3f3;

}

@import "../index";
