
.subcategory {
  flex: 1;
  overflow: scroll;
  position: relative;
  padding: 2*$unitMult+$unit;

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10*$unitMult+$unit;
    .btn {
      font-size: 3*$unitMult+$unit;
      margin: 1*$unitMult+$unit;
    }
  }


  .header {
    z-index: 999;
    position: relative;
  }

  .car-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;

  }
  .car-container {
    position: relative;
    overflow: hidden;
    .car {
      position: absolute;
      z-index: 1;
    }
    .car-lines {
      z-index: 10;
      position: relative;

      .line {
        border-bottom: solid 1px #000;
        transform-origin: 0px 0px;
        position: absolute;
        // box-shadow: 0px 1px 5px #000;
        opacity: .2;

      }
    }
    .car-buttons {
      z-index: 11;
      position: relative;

      .car-button {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 8*$unitMult+$unit;
        margin-top: -4*$unitMult+$unit;
        margin-left: -4*$unitMult+$unit;

        white-space: nowrap;

        // font-weight: bold;
        .icon {
          background: #333;

          &:active {
            background: #666;
          }
          display: flex;
          width: 8*$unitMult+$unit;
          height: 8*$unitMult+$unit;
          border-radius: 10*$unitMult+$unit;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img {
            width: 8*$unitMult+$unit;
            height: auto;
          }

        }
      }
    }
    .car {
      z-index: 1;
      img {
        position: absolute;
        top:0;
        width: 100%;

      }
    }
  }
}
