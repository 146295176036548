
.start {
  flex: 1;
  display: flex;
  flex-direction: column;


  .banner {
    // min-height: 50%;

    background: #000000;

    flex: 1;
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    position: relative;

    .tint {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      bottom: 0;
      opacity: .2;
      background-color: #000000;
    }

    h1 {
      z-index: 2;
      font-size: 5*$unitMult+$unit;
      font-weight: bold;

    }
  }


  .main {

    display: flex;
    flex-direction: column;
    padding: 2*$unitMult+$unit;
    // flex:1;
    background: $dimmedBackground;


    h2 {
      font-size: 4*$unitMult+$unit;
    }

    .copy {
      position: absolute;
      bottom: 1*$unitMult+$unit;
      right: 1*$unitMult+$unit;
      font-size: 2*$unitMult+$unit;
    }


    .go-to-app {
      height: 25*$unitMult+$unit;
      width: 25*$unitMult+$unit;

      position: relative;

      white-space: nowrap;
      margin-top:-8*$unitMult+$unit;

      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 2.75*$unitMult+$unit;
        // margin-bottom: 0;
      }


      svg.arrow {
        position: absolute;
        top:0;
        right:-8*$unitMult+$unit;
        path {
          fill: $primary;
        }
        width: 10*$unitMult+$unit;
      }

      .qr-code {
        background: #ffffff;
        padding: 3*$unitMult+$unit;
        align-items: center;
        display: flex;
        canvas {
          width: 20*$unitMult+$unit;
        }

      }
    }

    .buttons {
      display: flex;
      flex:1;
      padding: 5*$unitMult+$unit;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;


      .category-item {
        // border-radius: 1*$unitMult+$unit;

        background-color: $primary;
        color: #ffffff;

        &:active {
          opacity: .8;

        }

        box-shadow: 0 0 2*$unitMult+$unit rgba(0,0,0,.2);

        height: 25*$unitMult+$unit;
        width: 25*$unitMult+$unit;

        padding: 4*$unitMult+$unit;
        padding-bottom: 7*$unitMult+$unit;
        // margin: 1*$unitMult+$unit;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;

        svg {
          width: 100%;
          path, rect, polygon {
            fill: #ffffff;

          }
        }

        h3 {
          position: absolute;
          // margin-top: auto;
          bottom: 1*$unitMult+$unit;
          font-size: 3*$unitMult+$unit;


        }
      }
    }
  }


}
